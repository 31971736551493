import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import HalfBanner from "../components/halfBanner";
import RecentWork from "../components/recentwork";
import RequestQuote from "../components/quote";
import Brands from "../components/brands";

const Projects = () => {
    return (
        <Layout>
            <Seo title="Projects" />
            <HalfBanner
                title="Projects"
            />
            <RecentWork />
            <RequestQuote />
            <Brands />
        </Layout>
    );
};

export default Projects;