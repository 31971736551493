import React from 'react';
import Slider from "react-slick";
import Image from "../components/Image";

const Brands = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 5,
        slidesToScroll: 2,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <section className="brands-area pb-60 pt-60">
            <div className="container">
                <div className="brand-wrap">
                    <div className="row">
                        <div className="col-md-12">
                            <Slider {...settings}>
                                <div className="single-brand">
                                    <Image className="mx-auto" src="brands/sharon.jpeg" alt="" />
                                </div>
                                <div className="single-brand">
                                    <Image className="mx-auto" src="brands/favicol.jpg" alt="" />
                                </div>
                                <div className="single-brand">
                                    <Image className="mx-auto" src="brands/anchor.png" alt="" />
                                </div>
                                <div className="single-brand">
                                    <Image className="mx-auto" src="brands/asianpaint.png" alt="" />
                                </div>
                                <div className="single-brand">
                                    <Image className="mx-auto" src="brands/asis.png" alt="" />
                                </div>
                                <div className="single-brand">
                                    <Image className="mx-auto" src="brands/greenlam-logo.png" alt="" />
                                </div>
                                <div className="single-brand">
                                    <Image className="mx-auto" src="brands/jaquar.png" alt="" />
                                </div>
                                <div className="single-brand">
                                    <Image className="mx-auto" src="brands/lotus.png" alt="" />
                                </div>
                                <div className="single-brand">
                                    <Image className="mx-auto" src="brands/parryware.svg" alt="" />
                                </div>

                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Brands;