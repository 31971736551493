import React from 'react';
import { Link } from "gatsby";

const HalfBanner = (props) => {
    return (
        <section className="banner-area half relative" id="home">
            <div className="overlay overlay-bg"></div>
            <div className="container">
                <div className="row d-flex align-items-center justify-content-center">
                    <div className="about-content col-lg-12">
                        <h1 className="text-white">{props.title}</h1>
                        <p className="text-white link-nav">
                            <Link to="/">Home </Link>
                            <span className="lnr lnr-arrow-right"></span>
                            <span>{props.title}</span>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HalfBanner;