import React from 'react';
import Image from "../components/Image";

const RecentWork = () => {
    const works = [
        {
            url: "#",
            image: "g1.jpg",
            alt: "image",
            title: "Lavendar ambient interior"
        },
        {
            url: "#",
            image: "g2.jpg",
            alt: "image",
            title: "Ambient interior"
        },
        {
            url: "#",
            image: "g3.jpg",
            alt: "image",
            title: "Lavendar ambient interior"
        },
        {
            url: "#",
            image: "g4.jpg",
            alt: "image",
            title: "Ambient interior"
        }
    ];
    return (
        <section className="gallery-area">
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-12 pb-40 header-text text-center">
                        <h1 className="pb-10">Our Recent Works may impress you</h1>
                        <p>Who are in extremely love with eco friendly system.</p>
                    </div>
                </div>
                <div className="row">
                    {works.map((work, index) => {
                        return (
                            <div key={index} className={index % 2 === 0 ? "col-md-8" : "col-md-4"}>
                                <div className="single-gallery">
                                    <div className="content" style={{ backgroundImage: `url(${work.image})` }}>
                                        <a href={work.url} target="_blank">
                                            <div className="content-overlay"></div>
                                            <Image
                                                className="content-image img-fluid d-block mx-auto"
                                                src={work.image}
                                                alt={work.alt}
                                            />
                                            <div className="content-details fadeIn-bottom">
                                                <h3 className="content-title mx-auto">{work.title}</h3>
                                                <a
                                                    href={work.url}
                                                    className="primary-btn text-uppercase mt-20"
                                                >More Details</a
                                                >
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default RecentWork;